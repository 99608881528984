// Init recaptcha script in html <head> via onMounted.
export const useRecaptcha = () => {
	const runtimeConfig = useRuntimeConfig()

	// already instigated
	if (window.grecaptcha) {
		return
	}

	useHead({
		script: [
			{
				src: `https://www.google.com/recaptcha/api.js?render=${runtimeConfig.public.RECAPTCHA_SITE_KEY}`,
				id: 'recaptcha_api',
				async: true,
				defer: true,
			},
		],
	})

	if (typeof grecaptcha === 'undefined') {
		return
	}

	grecaptcha.ready()
}

// Invoke the challege and obtain recaptcha token from Google.
export const useExecuteRecaptcha = (action) => {
	const runtimeConfig = useRuntimeConfig()

	return grecaptcha.execute(runtimeConfig.public.RECAPTCHA_SITE_KEY, {
		action,
	})
}

// Method to verify response token via nuxt server api.
// Returns error messages if any.
export const useVerifyRecaptcha = async (responseToken) => {
	const runtimeConfig = useRuntimeConfig()

	const response = await $fetch(
		`${runtimeConfig.public.APP_URL}/api/recaptcha/verifyRecaptcha`,
		{
			method: 'POST',
			body: { responseToken },
		}
	)

	return response
}
